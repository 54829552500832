import React, { useState, useEffect, useRef } from "react";
import "./Partner.scss";

export default function Partner() {
  const dataPartner = [
    {
      name: "Open Commerce",
      img: "clients/open-commerce.png",
    },
    {
      name: "Add-On Development",
      img: "clients/add-on.png",
    },
    {
      name: "BIDV",
      img: "clients/bidv.jpg",
    },
    {
      name: "Bi Plus",
      img: "clients/biplus.png",
    },
    {
      name: "Brother Vietnam",
      img: "clients/brother.png",
    },
    {
      name: "CMC Global",
      img: "clients/cmc-global.png",
    },
    {
      name: "EVN Hanoi",
      img: "clients/evnhn.jpg",
    },
    {
      name: "FSoft",
      img: "clients/fpt.webp",
    },
    {
      name: "Hyperlogy",
      img: "clients/hyperlogy.jpg",
    },
    {
      name: "Luvina",
      img: "clients/luvina.jpg",
    },
    {
      name: "Mobifone",
      img: "clients/mobifone-logo.png",
    },
    {
      name: "Money Love",
      img: "clients/money-love.webp",
    },
    {
      name: "Paditech",
      img: "clients/paditech.png",
    },
    {
      name: "Samsung Display",
      img: "clients/samsung-display.png",
    },
    {
      name: "SmartOSC",
      img: "clients/smart-osc.jpg",
    },
    {
      name: "Smith-Nephew",
      img: "clients/smith-nephew.jpg",
    },
    {
      name: "TelSoft",
      img: "clients/telsoft.png",
    },
    {
      name: "Tinh Van",
      img: "clients/tinh-van.png",
    },
    {
      name: "Viettel",
      img: "clients/viettel.png",
    },
    {
      name: "Code Tốt",
      img: "clients/code-tot.png",
    },
    {
      name: "BraveBits",
      img: "clients/BraveBits.svg",
    },
  ];

  const [partners, setPartners] = useState([]);
  const carouselRef = useRef(null);

  useEffect(() => {
    setPartners([...dataPartner, ...dataPartner]);
  }, []);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const scrollAnimation = () => {
      if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
        carousel.scrollLeft = 0;
      } else {
        carousel.scrollLeft += 1;
      }
    };

    const animationId = setInterval(scrollAnimation, 20);

    return () => clearInterval(animationId);
  }, [partners]);

  return (
    <div className="partner-container">
      <h1 className="title">ĐỐI TÁC</h1>
      <div className="partner-carousel" ref={carouselRef}>
        {partners.map((partner, index) => (
          <div key={index} className="partner-item">
            <img src={partner.img} alt={partner.name} />
          </div>
        ))}
      </div>
    </div>
  );
}
