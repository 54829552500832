import React from "react";
import "./BackgroundColor.scss";

export default function BackgroundColor() {
  return (
    <div className="background">
      <div className="ellipse yellow"></div>
      <div className="ellipse purple"></div>
      <div className="rectangle bg"></div>
    </div>
  );
}
