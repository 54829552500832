import React from "react";
import "./HighlightText.scss";

export default function HighlightText({ text, highlightTexts }) {
  if (!text || !Array.isArray(highlightTexts) || highlightTexts.length === 0) {
    return <p>{text}</p>;
  }

  const splitText = (inputText, highlightArray) => {
    let result = [inputText];
    highlightArray.forEach((highlight) => {
      result = result.flatMap((part) => {
        if (typeof part === "string") {
          return part
            .split(highlight)
            .map((subPart, index, array) =>
              index < array.length - 1 ? [subPart, highlight] : subPart
            )
            .flat();
        }
        return part;
      });
    });
    return result;
  };

  const parts = splitText(text, highlightTexts);

  return (
    <p className="highlightTextContainer">
      {parts.map((part, index) =>
        highlightTexts.includes(part) ? (
          <span key={index} className="highlight">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </p>
  );
}
