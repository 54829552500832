import React from "react";
import "./TargetAudience.scss";
import BackgroundColor from "../BackgroundColor/BackgroundColor.jsx";

import { ReactComponent as CheckIcon } from "../svg/checkmark.svg";
import { ReactComponent as CalendarAndClock } from "../svg/calendarAndClock.svg";
import { ReactComponent as EducationSchool } from "../svg/educationSchool.svg";
import HighlighText from "../HighlightText/HighlightText.jsx";

export default function TargetAudience() {
  const dataAudience = {
    audience: [
      "Các bạn muốn tìm hiểu và tham gia vào ngành CNTT.",
      "Các bạn sinh viên đại học.",
      "Các bạn muốn tìm định hướng nghề nghiệp CNTT.",
      "Các bạn muốn tìm hiểu con đường học tập và tìm kiếm việc làm CNTT.",
    ],
    schedule: {
      duration: {
        desc: "Thời gian học: 3 buổi (1 giờ/buổi)",
        highlightText: ["3 buổi"],
      },
      format: {
        desc: "Hình thức đào tạo: Học tập trực tiếp tại trung tâm",
        highlightText: ["trực tiếp"],
      },
    },

    imgBackground: [
      "images/techMaster1.jpeg",
      "images/techMaster2.jpg",
      "images/techMaster3.png",
    ],
  };

  return (
    <div className="targetAudienceContainer">
      <div className="leftSide">
        <div className="audience">
          <h2 className="title">ĐỐI TƯỢNG HỌC VIÊN</h2>
          {dataAudience.audience.map((item) => {
            return (
              <div className="item" key={item}>
                <CheckIcon className="icon" />
                <p>{item}</p>
              </div>
            );
          })}
        </div>
        <div className="schedule">
          <h2 className="title">THỜI GIAN HỌC</h2>

          <div className="duration">
            <CalendarAndClock className="icon" />
            <HighlighText
              text={dataAudience.schedule.duration.desc}
              highlightTexts={dataAudience.schedule.duration.highlightText}
            />
          </div>

          <div className="fomat">
            <EducationSchool className="icon" />
            <HighlighText
              text={dataAudience.schedule.format.desc}
              highlightTexts={dataAudience.schedule.format.highlightText}
            />
          </div>
        </div>
      </div>
      <div className="rightSide">
        {dataAudience.imgBackground.map((item, index) => {
          return <img className="img" src={item} alt={item} />;
        })}

        {/*{" "}
        <img
          className="img"
          src="images/techMaster1.jpeg"
          alt="techMaster image"
          width={475}
          height={214}
        />
        <img
          className="img"
          src="images/techMaster2.jpg"
          alt="techMaster image"
          width={439}
          height={250}
        />
        <img
          className="img"
          src="images/techMaster3.png"
          alt="techMaster image"
          width={350}
          height={253}
        />{" "}
        */}
        <div className="backgroundImg"></div>
      </div>

      <BackgroundColor />
    </div>
  );
}
