import React, { useState } from "react";
import "./Testimonials.scss";

export default function Testimonials() {
  const [activeCardIndex, setActiveCardIndex] = useState(null);

  const dataTestimonials = [
    {
      avatar: "testimonial/luuminhsaokhue.jpg",
      userName: "Lưu Minh Sao Khuê",
      job: "Lập trình viên BigData & Machine Learning FSoft",
      title: "Công nghệ thông tin đã thay đổi cuộc đời mình",
      content: ` "Mình tốt nghiệp chuyên ngành Marketing ở tp Hồ Chí Minh 2014. Sau khi đi làm 2 năm, mình quyết định bay ra Hà nội, thuê trọ để thực tập dài hạn ở TechMaster. Người có kinh nghiệm chỉ cho người mới vào. Cứ 5:00 chiều là có 30 phút học tiếng Anh. Tốt nghiệp xong mình quay về tp HCM. Nhưng mình đã trót yêu Hà Nội, do đó đặt vé ra Hà Nội làm việc. Hiện mình lập trình viên ở FSoft."`,
    },
    {
      avatar: "testimonial/phuc-hoang.jpg",
      userName: "Phúc Hoàng",
      job: "Lập trình viên Sun*",
      title: "Trung tâm học tập uy tín",
      content: `  "Mình học kiến trúc, làm công ty xây dựng Hàn Quốc. Công việc không liên quan đến thiết kế mà xử lý sự vụ. Mình quyết định học lập trình Web để thỏa đam mê sáng tạo. Nghỉ làm không lương hơn 6 tháng thực sự áp lực về tài chính. Chỉ sau khi mình xin được việc lập trình web ở Sun*, bố mẹ và bạn gái mới biết. Những kỹ năng học được ở TechMaster đáp ứng được ngay yêu cầu tại nơi mình làm."`,
    },
    {
      avatar: "testimonial/pham-bao-nam.png",
      userName: "Phạm Bảo Nam",
      job: "Lập trình viên Onemount Group",
      title: "Một nơi để khởi đầu",
      content: ` "Mình học chuyên ngành khoa cơ khí ở 1 trường đại học ở Hà Nội, nhưng có sở thích về lập trình. Đầu năm 2016 mình đăng ký thực tập dài hạn ở Techmaster. khởi đầu mình học lập trình khá vất vả so với nhiều bạn khác ở trung tâm. Cũng nhờ sự chỉ bảo tận tình của thầy Cường và anh Huy thì mình đã kiên trì vượt qua giai đoạn khởi đầu đó và theo lập trình đến bây giờ."`,
    },
    {
      avatar: "testimonial/pham-thi-thu.jpg",
      userName: "Phạm Thị Thu",
      job: "Lập trình viên BraveBits",
      title: "Cảm ơn TechMaster",
      content: ` "Mình học ở TechMaster khoá Front-end chỉ 5 tháng nhưng học được rất nhiều kiến thức. Chưa bao giờ hối hận khi đăng kí học ở đây. Cách giảng dạy và quan tâm của thầy cô ở đây rất chu đáo và dễ hiểu. Nhiều hôm gần 12 giờ đêm chát Zalo hỏi bài tập mà vẫn được nhận được giải thích chi tiết. Những bạn nữ muốn học CNTT có thể tham khảo chương trình học ở TechMaster."`,
    },
    {
      avatar: "testimonial/pham-tuan-anh.jpg",
      userName: "Phạm Anh Tuấn",
      job: "Lập trình viên Cowell",
      title: "Thực hành thực tế",
      content: ` "Mình tốt nghiệp kế toán nhưng lại làm ở một công ty xây dựng cầu đường liên tục phải đi xa, mình đã phải bỏ để đi làm tư vấn bán hàng. Nhưng mình vẫn thích được làm kỹ thuật. Mình thực tập ở TechMaster 6 tháng. Sau khi tốt nghiệp, mình được TechMaster giới thiệu sang Cowell và hiện mình vẫn đang làm việc ở đây."`,
    },
    {
      avatar: "testimonial/vu-cong-anh.jpg",
      userName: "Vũ Công Anh",
      job: "Lập trình viên FSoft",
      title: "Mình đã học được rất nhiều điều ở TechMaster",
      content: ` "Điều mình thấy đặc biệt khi học tại TechMaster là mình học được nhiều hơn. Mình được thực hành bấm dây mạng, cấu hình hệ thống LAN, WIFI, cài đặt Linux server. Mình đang làm dở đồ án tốt nghiệp Web Front End thì phải nghỉ sinh em bé. Với kiến thức học được ở TechMaster, mình có thể hỗ trợ công ty phần mềm của chồng mình rất nhiều. Thật may mắn vì đã biết đến và làm việc với các thầy ở TechMaster Vietnam."`,
    },
  ];

  const handleCardClick = (index) => {
    setActiveCardIndex(activeCardIndex === index ? null : index);
  };

  return (
    <div className="testimonials-container">
      <h1 className="title">CẢM NHẬN CỦA HỌC VIÊN VỀ TECHMASTER</h1>
      <div className="testimonials-wrapper">
        {dataTestimonials.map((item, index) => {
          return (
            <div
              className={`testimorial-card ${
                activeCardIndex === index ? "active" : ""
              }`}
              key={index}
              onClick={() => handleCardClick(index)}
            >
              <div className="content">
                <em className="desc">{item.title}</em>
                <p>{item.content}</p>
              </div>

              {/* <div className="line"></div> */}

              <div className="userInfor">
                <img className="avatar" src={item.avatar} alt="avatar user" />
                <h1 className="userName">{item.userName}</h1>
                <p className="job">{item.job}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="backgroundTestimonials">
        <div className="ellipse yellow"></div>
        <div className="ellipse purple"></div>
        <div className="ellipse blue"></div>
      </div>
    </div>
  );
}
