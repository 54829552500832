import React, { useState, useEffect } from "react";
import { Link, scroller } from "react-scroll";
import { ReactComponent as MenuIcon } from "../svg/iconMenu.svg";

import { debounce } from "lodash";
import "./Navbar.scss";

const Navbar = () => {
  const [scrollActiveSection, setScrollActiveSection] = useState("home");
  const [clickActiveSection, setClickActiveSection] = useState("home");

  const navbarData = {
    logo: {
      src: "images/logo_TechMaster.png",
      alt: "logo TechMaster",
    },
    menu: [
      {
        name: "Giới thiệu",
        href: "#home",
        to: "home",
      },
      {
        name: "Đối tượng",
        href: "#targetAudience",
        to: "targetAudience",
      },
      {
        name: "Nội dung",
        href: "#courseContent",
        to: "courseContent",
      },
      {
        name: "Giảng viên",
        href: "#instructor",
        to: "instructor",
      },
      {
        name: "Liên hệ",
        href: "#contact",
        to: "contact",
      },
    ],
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollPosition = window.scrollY;
      const offset = window.innerHeight * 0.3; // Offset for scroll detection

      for (const item of navbarData.menu) {
        const element = document.getElementById(item.to);
        if (element) {
          const elementTop = element.offsetTop - offset;
          const elementBottom = elementTop + element.offsetHeight;

          if (scrollPosition >= elementTop && scrollPosition < elementBottom) {
            setScrollActiveSection(item.to);
            break;
          }
        }
      }
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Custom click handler
  const handleClick = (to) => {
    scroller.scrollTo(to, {
      duration: 500,
      smooth: true,
      offset: 0, // No offset for click navigation
    });
    setClickActiveSection(to);
  };

  // Determine active class
  const getActiveClass = (section) => {
    if (scrollActiveSection === section) return "scroll-active";
    if (clickActiveSection === section) return "click-active";
    return "";
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="nav">
      <nav
        className={
          isMobileMenuOpen
            ? "navbar-container mobile-menu-open"
            : "navbar-container"
        }
      >
        <a
          href="https://techmaster.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={navbarData.logo.src}
            alt={navbarData.logo.alt}
            className="logo"
          />
        </a>
        <ul className="list-menu">
          {navbarData.menu.map((item) => (
            <li key={item.name} className="menu-item">
              <Link
                to={item.to}
                smooth={true}
                duration={500}
                offset={0} // No offset in Link component
                className={getActiveClass(item.to)}
                onClick={() => handleClick(item.to)} // Use custom click handler
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <MenuIcon className="iconMenu" onClick={toggleMobileMenu} />
    </div>
  );
};

export default Navbar;
