import React from "react";
import "./Footer.scss";

export default function Footer() {
  return (
    <div className="footer-container">
      <p>© 2024 TechMaster Việt Nam. All rights reserved. Privacy Policy</p>
    </div>
  );
}
