import React from "react";
import "./Contact.scss";

import { ReactComponent as FacbookIcon } from "../svg/iconFacebook.svg";
import { ReactComponent as YoutubeIcon } from "../svg/iconYoutube.svg";
import { ReactComponent as DiscordIcon } from "../svg/iconDiscord.svg";
import { ReactComponent as LocationIcon } from "../svg/iconLocation.svg";

export default function Contact() {
  const companyAddress = [
    {
      link: "https://maps.app.goo.gl/7WL9gS4dVKE6W7u46",
      name: "Cơ sở Tố Hữu: Tầng 12A, tòa nhà Viwaseen Tower, số 48, Tố Hữu, Lê Văn Lương kéo dài, Hà Nội",
    },
    {
      link: "https://maps.app.goo.gl/zeGhiu9r8A2h6x2EA",
      name: "Cơ sở Nguyễn Đình Chiểu: Số 14, ngõ 4, Nguyễn Đình Chiểu, Hà Nội",
    },
  ];

  const dataTelemarketer = {
    avatar: "images/telemarketerAvatar.jpeg",
    name: "Ms Mẫn",
    phone: "0963023185",
    email: "phamman@techmaster.vn",
    subject: "Yêu cầu thông tin",
    body: "Xin chào, tôi muốn biết thêm thông tin về khóa học Foundation Basic",
  };

  const dataSocialMedia = [
    {
      icon: <FacbookIcon className="icon" />,
      link: "https://www.facebook.com/techmastervn",
    },
    {
      icon: <YoutubeIcon className="icon" />,
      link: "https://www.youtube.com/@TechMasterVietnam",
    },
    {
      icon: <DiscordIcon className="icon" />,
      link: "https://discord.gg/yQjRTFXb7a",
    },
  ];

  return (
    <div className="contact-container">
      {/* <div className="form-container">
        <div className="title">
          <FormIcon className="icon" />
          <h1>ĐĂNG KÝ NHẬN TƯ VẤN</h1>
        </div>
        <Form />
      </div>
      <div className="line"></div> */}

      <div className="title-container">
        {/* <SupportIcon className="icon" /> */}
        <h1>LIÊN HỆ VỚI CHÚNG TÔI ĐỂ ĐƯỢC HỖ TRỢ MIỄN PHÍ</h1>
        <a
          href="https://m.me/techmastervn"
          target="blank"
          rel="noopener noreferrer"
          className="button"
        >
          Liên hệ ngay
        </a>
      </div>
      <div className="supportInfor">
        {/* <div className="telemaketerCard">
          <img src={dataTelemarketer.avatar} alt="avatar telemarketer" />
          <div className="info">
            <h1>Tư vấn viên</h1>
            <div className="bottom-side">
              <div className="phone">
                <PhoneIcon className="icon" />
                <a href={`tel:${dataTelemarketer.phone}`}>
                  {dataTelemarketer.name} - {dataTelemarketer.phone}
                </a>
              </div>
              <div className="email">
                <EmailIcon className="icon" />
                <a
                  href={`mailto:${
                    dataTelemarketer.email
                  }?subject=${encodeURIComponent(
                    dataTelemarketer.subject
                  )}&body=${encodeURIComponent(dataTelemarketer.body)}`}
                >
                  {dataTelemarketer.email}
                </a>
              </div>
            </div>
          </div>
        </div> */}

        <div className="socialMediaContainer">
          <h1 className="title">
            Theo dõi chúng tôi để nhận được tin tức mới nhất
          </h1>
          <div className="socialMedia">
            {dataSocialMedia.map((social, index) => (
              <a key={index} href={social.link}>
                {social.icon}
              </a>
            ))}
          </div>
        </div>

        <div className="companyAddress">
          <h1 className="title">Cơ sở đào tạo</h1>
          <ul>
            {companyAddress.map((address, index) => (
              <li key={index}>
                <LocationIcon className="icon" />
                <a href={address.link} target="blank" rel="noopener noreferrer">
                  {address.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
