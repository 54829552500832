import React, { useEffect, useRef, useState } from "react";
import "./Home.scss";
import { ReactComponent as PlayIcon } from "../svg/polygon.svg";
import BackgroundColor from "../BackgroundColor/BackgroundColor";
import HighlightText from "../HighlightText/HighlightText";
import ShareButton from "../ShareButton/ShareButton";

const Home = () => {
  const dataCourse = {
    cousreName: "FOUNDATION BASIC",
    fee: "FREE",
    thumbnail: "images/thumbnail.jpg",
    cousreDesc:
      "Khoá học được thiết kế đặc biệt để trang bị cho bạn những kiến thức và kỹ năng cơ bản, cần thiết trong lĩnh vực IT.",
    highlightText: ["kiến thức", "kỹ năng", "cần thiết"],
    offer: [
      {
        desc: "Học trực tiếp tại trung tâm",
        highlightText: ["trực tiếp"],
        color: "28AFE4",
      },
      {
        desc: "Khóa học hoàn toàn miễn phí",
        highlightText: ["miễn phí"],
        color: "FFC700",
      },
    ],
    // studientCount: 25,
  };

  const [isDesktopVideoPlaying, setIsDesktopVideoPlaying] = useState(false);
  const [isMobileVideoPlaying, setIsMobileVideoPlaying] = useState(false);
  const desktopVideoRef = useRef(null);
  const mobileVideoRef = useRef(null);

  useEffect(() => {
    const desktopVideo = desktopVideoRef.current;
    const mobileVideo = mobileVideoRef.current;
    
    const handleDesktopVideoEnd = () => {
      setIsDesktopVideoPlaying(false);
    };

    const handleMobileVideoEnd = () => {
      setIsMobileVideoPlaying(false);
    };
  
    if (desktopVideo) {
      desktopVideo.addEventListener('ended', handleDesktopVideoEnd);
    }
    if (mobileVideo) {
      mobileVideo.addEventListener('ended', handleMobileVideoEnd);
    }
  
    return () => {
      if (desktopVideo) {
        desktopVideo.removeEventListener('ended', handleDesktopVideoEnd);
      }
      if (mobileVideo) {
        mobileVideo.removeEventListener('ended', handleMobileVideoEnd);
      }
    };
  }, []);

  const handleDesktopPlayClick = () => {
    setIsDesktopVideoPlaying(true);
    setTimeout(() => {
      if (desktopVideoRef.current) {
        desktopVideoRef.current.play().catch(error => {
          console.error("Lỗi khi phát video desktop:", error);
        });
      }
    }, 500);
  };

  const handleMobilePlayClick = () => {
    setIsMobileVideoPlaying(true);
    setTimeout(() => {
      if (mobileVideoRef.current) {
        mobileVideoRef.current.play().catch(error => {
          console.error("Lỗi khi phát video mobile:", error);
        });
      }
    }, 500);
  };

  return (
    <div className="homeContainer">
      <a
        href="https://techmaster.vn"
        target="blank"
        rel="noopener noreferrer"
        className="headerMobile"
      >
        <img
          src="images/logo_TechMaster.png"
          alt="logo TechMaster"
          className="logo"
        />
      </a>
      <div className="heroSection">
        <h1 className="title">{dataCourse.cousreName}</h1>
        <div className="badge">
          <p>{dataCourse.fee}</p>
        </div>
        <HighlightText
          text={dataCourse.cousreDesc}
          highlightTexts={dataCourse.highlightText}
        />
        <div className="thumbnailMobile">
        {!isMobileVideoPlaying && (
          <>
            <img src={dataCourse.thumbnail} alt="thumbnail img" />
            <div className="btnPlay" onClick={handleMobilePlayClick}>
              <div className="rectangle"></div>
              <PlayIcon
                style={{
                  width: "30px",
                  height: "30px",
                  fill: "#FFFFFF",
                  position: "absolute",
                  top: "calc(50% - 15px)",
                  left: "calc(50% - 11px)",
                }}
              />
            </div>
          </>
        )}
          <div className="videoContainer" style={{ display: isMobileVideoPlaying ? 'block' : 'none' }}>
          <video controls ref={mobileVideoRef} className="mobile" playsInline webkit-playsinline="true">
            <source src="videos/videoThumnail.mp4" type="video/mp4" data-quality="high" />
            Trình duyệt của bạn không hỗ trợ thẻ video.
          </video>
        </div>
          <div className="bg-thumbnail"></div>
        </div>
        <div className="offer">
          {dataCourse.offer.map((item) => {
            return (
              <div className="offerItem" key={item.desc}>
                <div
                  className="ellipse"
                  style={{ backgroundColor: `#${item.color}` }}
                ></div>
                <HighlightText
                  text={item.desc}
                  highlightTexts={item.highlightText}
                />
              </div>
            );
          })}
        </div>

        <div className="btnContainer">
          <a
            href="https://techmaster.sg.larksuite.com/share/base/form/shrlgfuCkTZRD2Ai9T0dN0KMQBb"
            target="blank"
            rel="noopener noreferrer"
            className="btnEnroll"
          >
            <p>Đăng ký ngay</p>
          </a>
          <ShareButton
            url={window.location.href}
            title={`Check out this course: ${dataCourse.cousreName}`}
          />
        </div>
      </div>

      <div className="thumbnail">
        {!isDesktopVideoPlaying && (<>
        <img src={dataCourse.thumbnail} alt="thumbnail img" />
        <div className="btnPlay" onClick={handleDesktopPlayClick}>
          <div className="rectangle"></div>
          <PlayIcon
            style={{
              width: "40px",
              height: "40px",
              fill: "#FFFFFF",
              position: "absolute",
              top: "calc(50% - 20px)",
              left: "calc(50% - 15px)",
            }}
            />
        </div>
            </>
        )}
        <div className="bg-thumbnail"></div> 
       
        <div className="videoContainer" style={{ display: isDesktopVideoPlaying ? 'block' : 'none' }}>
          <video controls ref={desktopVideoRef} className="desktop">
            <source src="videos/videoThumnail.mp4" type="video/mp4" data-quality="high" />
            Trình duyệt của bạn không hỗ trợ thẻ video.
          </video>
        </div>
      </div>
      <div className="footer">
        <div className="backgroundFooter">
          <div className="rectangle"></div>
        </div>
      </div>

      <BackgroundColor />
    </div>
  );
};

export default Home;
