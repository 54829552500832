import React from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import TargetAudience from "./components/TargetAudience/TargetAudience";
import CourseContent from "./components/CourseContent/CourseContent";
import Instructor from "./components/Instructor/Instructor";
import Contact from "./components/Contact/Contact";
import Voucher from "./components/Voucher/Voucher";
import OtherCourses from "./components/OtherCourses/OtherCourses";
import Testimonials from "./components/Testimonials/Testimonials";
import Footer from "./components/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
      </header>
      <section id="home" className="section">
        <Home />
      </section>

      <section id="targetAudience" className="section">
        <TargetAudience />
      </section>

      <Voucher />

      <section id="courseContent" className="section">
        <CourseContent />
      </section>

      <section id="instructor" className="section">
        <Instructor />
      </section>

      <OtherCourses />

      <Testimonials />

      <section id="contact" className="section">
        <Contact />
      </section>

      <Footer />

      <ToastContainer
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
