import React from "react";
import "./CourseContent.scss";

import { ReactComponent as FishTail } from "../svg/fishTail.svg";
import { ReactComponent as FishHead } from "../svg/fishHead.svg";

export default function CourseContent() {
  //data 2 content and < 4 day using fish-born-time-line

  const dataCourseContent = [
    {
      name: "day 1",
      content: [
        "Công nghệ thông tin là gì?",
        "Mục tiêu của các công nghệ thông tin",
      ],
    },
    {
      name: "day 2",
      content: [
        "Các bộ môn trong công nghệ thông tin",
        "Các môn học trong công nghệ thông tin",
      ],
    },
    {
      name: "day 3",
      content: [
        "Các nghề nghiệp trong công nghệ thông tin",
        "Định hướng nghề nghiệp",
      ],
    },
  ];
  return (
    <div className="courseContent-container">
      <h1 className="title">LỘ TRÌNH KHÓA HỌC</h1>
      <div className="fish-born-time-line">
        <div className="skeleton">
          <FishTail width={150} height={400} className="fish-tail" />
          <div className="fish-back-born"></div>
          <FishHead width={200} height={260} className="fish-head" />
        </div>
        <div className="content-couse">
          {dataCourseContent.map((item, index) => {
            return (
              <div
                key={index}
                className="item"
                style={{
                  maxWidth: `calc(100% / ${dataCourseContent.length} - 10px)`,
                }}
              >
                <div className="upper-content">
                  <p className="desc">{item.content[0]}</p>
                  <div className="line"></div>
                </div>
                <div className="day">
                  <p>{index + 1}</p>
                </div>
                <div className="lower-content">
                  <div className="line"></div>
                  <p className="desc">{item.content[1]}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="time-line-using-for-mobile">
        {dataCourseContent.map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="day">
                <p>{item.name}</p>
              </div>
              <div className="content">
                <div className="upper-content">{item.content[0]}</div>
                <div className="lower-content">{item.content[1]}</div>
              </div>
            </div>
          );
        })}

        <div className="line">
          <div className="ellipse"></div>
          <div className="ellipse"></div>
          <div className="ellipse"></div>
          <div className="ellipse"></div>
          <div className="ellipse"></div>
          <div className="ellipse"></div>
        </div>
      </div>
      <div className="backgroundCouresContent">
        <div className="rectangle"></div>
        <div className="ellipse"></div>
        <div className="ellipse"></div>
        <div className="ellipse"></div>
      </div>
    </div>
  );
}
