import React from "react";
import { ReactComponent as FacebookIcon } from "../svg/facebookWithoutCircle.svg";
import "./ShareButton.scss";

const ShareButton = ({ url, title }) => {
  const handleShare = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    window.open(shareUrl, "FacebookShare");
  };

  return (
    <div>
      <button className="btnShare btnFacebookShare" onClick={handleShare}>
        <FacebookIcon className="icon" />
        <span className="text">Chia sẻ</span>
      </button>
    </div>
  );
};

export default ShareButton;
