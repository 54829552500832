import React, { useEffect, useState } from "react";
import "./OtherCourses.scss";
import { ReactComponent as UsertieIcon } from "../svg/iconUserTie.svg";
import { ReactComponent as ClockIcon } from "../svg/clock2.svg";
import { ReactComponent as GroupIcon } from "../svg/group2.svg";
import { ReactComponent as NextIcon } from "../svg/rightArrow.svg";
import { ReactComponent as PrevIcon } from "../svg/leftArrow.svg";
import Partner from "../Partner/Partner";
import BackgroundColor from "../BackgroundColor/BackgroundColor";

export default function OtherCourses() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(3);

  const dataOtherCourses = [
    {
      name: "Web Frontend + ReactJS",
      desc: "Tập trung vào lập trình Web Frontend, bao gồm cả thiết kế UI/UX và các framework được sử dụng để xây dựng giao diện hot nhất hiện nay như ReactJS",
      thumbNail: "images/route_frontend.jpg",
      link: "https://frontend.techmaster.vn/",
      instructor:
        "Bùi Xuân Hiên, Nguyễn Văn Thuận, Nguyễn Quang Huy, Nguyễn Xuân Ba, Bùi Đức Thịnh",
      duration: "5 tháng - 60 buổi",
      // studentEnrolled: "90+",
      linkEnrrol: "https://frontend.techmaster.vn/#contact",
      cost: "17.000.000đ",
    },
    {
      name: "Java Springboot Fullstack",
      desc: "Lập trình Fullstack từ Frontend tới Backend với Java, khóa học duy nhất bao gồm cả kiến thức về cấu trúc dữ liệu và giải thuật",
      thumbNail: "images/java-spring-boot-min.jpg",
      link: "https://java.techmaster.vn/",
      instructor: "Bùi Văn Hiên, Hoàng Văn Công, Nguyễn Văn Ba",
      duration: "7 tháng - 84 buổi",
      // studentEnrolled: "80+",
      linkEnrrol: "https://java.techmaster.vn/#register",
      cost: "25.000.000đ",
    },
    {
      name: "DevOps",
      desc: "DevOps là một văn hóa làm việc kết hợp giữa kỹ sư phát triển phần mềm (dev) với bộ phận operator (kỹ sư hệ thống, nhân viên bảo mật, kỹ sư mạng, kỹ sư…",
      thumbNail: "images/devops-min.jpg",
      link: "https://devops.techmaster.vn/",
      instructor: "Cao Đăng Sao, Đào Ngọc Lâm, Bùi Đức Thịnh, Hoàng Văn Phú",
      duration: "5 tháng - 42 buổi",
      // studentEnrolled: "30+",
      linkEnrrol: "https://devops.techmaster.vn/",
      cost: "9.000.000đ",
    },
    {
      name: "Lộ trình IOS",
      desc: "Xây dựng và phát triển ứng dụng tương thích với hệ điều hành iOS, thông qua ngôn ngữ lập trình Swift được sáng tạo độc quyền bởi Apple.",
      thumbNail: "images/ios.jpg",
      link: "https://ios.techmaster.vn",
      instructor: "Tào Thuý Quỳnh, Hà Văn Đức, Vũ Thiện Tùng, Lê Văn Việt",
      duration: "3 tháng - 38 buổi",
      // studentEnrolled: "30+",
      linkEnrrol: "https://ios.techmaster.vn/#idLienHe",
      cost: "12.500.000đ",
    },
    {
      name: "Lộ trình Web PHP Laravel Fullstack",
      desc: "Thiết kế, lập trình website với PHP Laravel fullstack. Kế thừa giáo trình Frontend trọn vẹn và Backend mới nhất với PHP 8.x Laravel 9.x",
      thumbNail: "images/php.jpg",
      link: "https://php.techmaster.vn/",
      instructor:
        "Nguyễn Văn Thuận, Nguyễn Thế Huy, Trần Huy Hoàng, Nguyễn Xuân Ba",
      duration: "7 tháng - 70 buổi",
      // studentEnrolled: "40+",
      linkEnrrol: "https://php.techmaster.vn/#contact",
      cost: "10.000.000đ",
    },
    {
      name: "Lập trình di động Flutter cho IOS - Android",
      desc: "Flutter là framework xây dựng ứng dụng mobile đa nền tảng từ Google, tốc độ của Flutter được đánh giá là tiệm cận Native nhất...",
      thumbNail: "images/flutter-min.jpg",
      link: "https://flutter.techmaster.vn/",
      instructor: "Tào Quang Hiếu, Nguyễn Mạnh Toàn, Nguyễn Thế Toán",
      duration: "5 tháng - 46 buổi",
      // studentEnrolled: "50+",
      linkEnrrol: "https://flutter.techmaster.vn/#contact",
      cost: "12.500.000đ",
    },
  ];

  const totalCourses = dataOtherCourses.length;

  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width < 768) {
        setCardsToShow(1);
      } else if (window.screen.width < 1200) {
        setCardsToShow(2);
      } else {
        setCardsToShow(3);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Adjust currentIndex if it's out of bounds after resizing
    if (currentIndex > totalCourses - cardsToShow) {
      setCurrentIndex(Math.max(0, totalCourses - cardsToShow));
    }
  }, [cardsToShow, currentIndex, totalCourses]);

  const isFirstCard = currentIndex === 0;
  const isLastCard = currentIndex >= totalCourses - cardsToShow;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(totalCourses - cardsToShow, prevIndex + 1)
    );
  };

  const renderDots = () => {
    const dots = [];
    const totalDots = 3;

    for (let i = 0; i < totalDots; i++) {
      let isActive;
      if (i === 0) {
        isActive = isFirstCard;
      } else if (i === totalDots - 1) {
        isActive = isLastCard;
      } else {
        isActive = !isFirstCard && !isLastCard;
      }

      dots.push(<span key={i} className={`dot ${isActive ? "active" : ""}`} />);
    }
    return dots;
  };

  return (
    <div className="otherCourses-container">
      <h1 className="title">CÁC KHÓA HỌC KHÁC BẠN CÓ THỂ THAM KHẢO</h1>
      <div className="otherCourses-wrapper">
        <div className="navigation-buttons">
          {!isFirstCard && (
            <button onClick={handlePrev} className="prev">
              <PrevIcon className="icon" />
            </button>
          )}
          {!isLastCard && (
            <button onClick={handleNext} className="next">
              <NextIcon className="icon" />
            </button>
          )}
        </div>
        <div className="wrapper">
          {dataOtherCourses
            .slice(currentIndex, currentIndex + cardsToShow)
            .map((item, index) => (
              <a
                href={item.link}
                target="blank"
                rel="noopener noreferrer"
                className="course-card"
                key={index}
              >
                <img
                  className="thumbnail"
                  src={item.thumbNail}
                  alt={item.name}
                />
                <div className="badge">
                  <p>{item.cost}</p>
                </div>
                <h1 className="title">{item.name}</h1>
                <p className="desc">{item.desc}</p>
                <div className="instructor">
                  <UsertieIcon className="icon" />
                  <p>
                    Giảng viên: <span>{item.instructor}</span>
                  </p>
                </div>
                <div className="duration">
                  <ClockIcon className="icon" />
                  <p>
                    Thời gian học: <span>{item.duration}</span>
                  </p>
                </div>
                {/* <div className="studenEnrolled">
                  <GroupIcon className="icon" />
                  <p>
                    Số người đã học: <span>{item.studentEnrolled}</span>
                  </p>
                </div> */}
                <div className="btn-container">
                  <a href={item.linkEnrrol} className="btn-enrol-now">
                    Đăng ký ngay
                  </a>
                </div>
              </a>
            ))}
        </div>
        <div className="pagination">{renderDots()}</div>
      </div>
      <Partner />
      <BackgroundColor />
    </div>
  );
}
