import React from "react";
import "./Voucher.scss";

import { ReactComponent as GiftIcon } from "../svg/gift.svg";
import { ReactComponent as CouponIcon } from "../svg/coupon.svg";
import HighlightText from "../HighlightText/HighlightText.jsx";

export default function Voucher() {
  const dataVoucher = {
    title: {
      text: "Khóa học free nhận quà miễn phí",
      highlightText: ["free", "miễn phí"],
    },
    subtitle: {
      text: "Đăng ký ngay khóa học FOUNDATION BASIC để có cơ hội",
      highlightText: ["FOUNDATION BASIC"],
    },
    offer: [
      {
        icon: <GiftIcon className="icon" />,
        desc: "Quà lưu niệm mang thương hiệu TechMaster.",
        highlightText: [],
      },
      {
        icon: <CouponIcon className="icon" />,
        desc: "Voucher giảm tới 20% học phí tại TechMaster.",
        highlightText: ["20%"],
      },
    ],
  };

  return (
    <div className="voucherContainer">
      <HighlightText
        text={dataVoucher.title.text}
        highlightTexts={dataVoucher.title.highlightText}
      />
      <HighlightText
        text={dataVoucher.subtitle.text}
        highlightTexts={dataVoucher.subtitle.highlightText}
      />
      <div className="offer">
        {dataVoucher.offer.map((item, index) => (
          <div key={index} className="offerItem">
            {item.icon}
            <HighlightText
              text={item.desc}
              highlightTexts={item.highlightText}
            />
          </div>
        ))}
      </div>
      <a
        href="https://techmaster.sg.larksuite.com/share/base/form/shrlgfuCkTZRD2Ai9T0dN0KMQBb"
        target="blank"
        rel="noopener noreferrer"
        className="btn-enroll-now"
      >
        Nhận ngay
      </a>
    </div>
  );
}
