import React from "react";
import "./Instructor.scss";

import { ReactComponent as IconUserTie } from "../svg/iconUserTie.svg";
import { ReactComponent as IconCode } from "../svg/iconCode.svg";
import { ReactComponent as IconBook } from "../svg/iconWritingBook.svg";

import BackgroundColor from "../BackgroundColor/BackgroundColor";

export default function Instructor() {
  const dataInstructor = {
    name: "Tạ Văn Dũng",
    desc: "Director - TechMaster Vn",
    avatar: "images/avatarInstructor.jpg",
    linkFlow: "https://www.facebook.com/search/top?q=techmaster%20vietnam",
    infor: [
      {
        icon: <IconUserTie className="icon" />,
        title: "Chức vụ:",
        desc: [
          "Giám đốc điều hành của TechMaster Việt Nam",
          "Nhà sáng lập tổ chức mã nguồn mở Young Monkeys",
        ],
      },
      {
        icon: <IconCode className="icon" />,
        title: "Kinh nghiệm:",
        desc: [
          "Former team leader của 2 dự án blockchain tại LINE Technology Việt Nam",
          "11 năm kinh nghiệm trong phát triển phần mềm và quản lý dự án IT",
        ],
      },
      {
        icon: <IconBook className="icon" />,
        title: "Tác giả sách:",
        desc: [
          `Tác giả của "Làm chủ các mẫu thiết kế kinh điển trong lập trình"`,
          `Tác giả của "Những nguyên tắc sống còn trong lập trình"`,
          `Đồng tác giả của "AI cho người mới bắt đầu" (sắp ra mắt năm 2024)`,
        ],
      },
    ],
  };

  return (
    <div className="instructor-container">
      <h1 className="title">THÔNG TIN GIẢNG VIÊN</h1>
      <div className="instructor-content">
        <div className="instructor-card">
          <img
            className="avatar"
            src={dataInstructor.avatar}
            alt="instructor avatar"
          ></img>
          <h3 className="instructor-name">{dataInstructor.name}</h3>
          <em className="instructor-desc">{dataInstructor.desc}</em>
          <a href={dataInstructor.linkFlow} className="btn-flow">
            FOLLOW
          </a>
        </div>
        <div className="instructor-infor">
          {dataInstructor.infor.map((item, index) => {
            return (
              <div className="infor-item" key={index}>
                {item.icon}
                <div className="item-content">
                  <p className="infor-title">{item.title}</p>
                  <ul className="infor-desc">
                    {item.desc.map((desc) => (
                      <li key={desc}>{desc}</li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <BackgroundColor />
    </div>
  );
}
